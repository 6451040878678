
.cards{
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: center;
    align-items: center; */
}
.cradimg{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    height: 100%;
}