@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;500;700&family=Climate+Crisis&family=Poppins:wght@400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;1,300;1,600&family=Roboto:ital,wght@0,300;1,400&family=Work+Sans&display=swap');

/* @font-face {
    font-family: "neuelt";
   } */
  
  html {
    font-size: 16px !important;
  }
  
  body {
    background-color: #EAF2F6 !important;
    font-family: "neuelt", serif, sans-serif !important;
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *:lang(en) {
    font-family: 'Poppins', sans-serif;
}
  *:lang(ar) {
    font-family: 'Cairo', sans-serif;
}
  
  .chakra-accordion__button[aria-expanded="true"],
  .sidebar-link.active {
    /* background-color: #ddd !important;
    color: #000 !important; */
  }
  
  .chakra-link.active {
    color: #602ACE;
    border-bottom: 2px solid #602ACE
  }


  /*  */
  *{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
body{
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    background: rgba( 71, 147, 227, 1);
}
h2{
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    padding: 30px 0;
}
a{
    text-decoration: none !important;
    color: white !important;
}

/* Table Styles */

.table-wrapper{
     box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
     width: 100%;
     overflow: auto;
}

.fl-table {
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.fl-table thead th {
    color: #ffffff;
    background: #46A0CD;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}

.fl-table tr:nth-child(even) {
    background: rgb(235, 233, 233);
}

/* Responsive */
/* 
@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }
    .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .fl-table thead th:last-child{
        border-bottom: none;
    }
    .fl-table thead {
        float: left;
    }
    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .fl-table tbody tr {
        display: table-cell;
    }
    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tbody td {
        display: block;
        text-align: center;
    }
} */

.good{
    background-color:#fff !important;
    color: #46A0CD !important;
    border: 1px solid #46A0CD !important;
}