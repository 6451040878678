.formlogin {
    margin: 50px 50px;
}

.niyat_clinck {
    font-size: 30px;
    font-weight: 300;
    color: #03BEC7;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-family: Cairo;
    font-size: 56px;
    font-weight: 600;
    line-height: 105px;
    letter-spacing: 0em;
    text-align: center;
}
.username,
.password,
.email {
    width: 100%;
    padding: 10px;
    border:1px solid #ddd;
    color: #868e97;
    font-size: 14px;
    margin-bottom: 30px;
    outline: none;
    border-radius: 6px;
}
.error {
    color: red
}
.submitdiv{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 }
.forgotdiv{
    display:flex;
    flex-direction: column;
    margin-top: 20px;
}
.rememberMe {
    margin-inline-start: 10px;
    color: #03BEC7;

}

.forgotpassword {
    margin: 0;
    margin-inline-end: 30px;
    color: #03BEC7;
    transition: all 0.3;
}

.forgotpassword:hover {
    cursor: pointer;
    color: #03BEC7;
}

.back ,.send{
    border: none;
    width: 60px;
    height: 33px;
    color: #fff;
    background-color: #32c5d2;
    /* border-color: #E5E5E5; */
}

.submitbutton {
    border: none;
    color: #FFF;
    background-color: #03BEC7;
    min-width: 300px;
    height: 60px;
    font-size: 20px;
    border-radius: 8px;
    margin-top: 50px;
}

/* forgot com  */
/* .email {
    width: 100%;
    height: 34px;
    padding: 0px 12px;
    background-color: #fff;
    border: 1px solid #c2cad8;
    outline: none;
} */

.forgot_your_password {
    color: #32c5d2;
    margin-bottom: 5px;
}
.enter_email {
    color: #a0a9b4;
    font-size: 15px;
    line-height: 22px;
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 600px;
    margin: 0 auto;
}
.formForgotPassword{
    width: 600px;
    margin: 0 auto; 
}


@media screen and (min-width: 320px) and (max-width: 425px) {
    .submitdiv{
        display: block;
    }
    .form{
        width: 300px;
    }
    .rememberMe{
        font-size: 12px;
    }
    .formForgotPassword{
        width: 300px;
     }
    
}
@media screen and (min-width: 425px) and (max-width: 720px) {
    .form{
        width: 400px;
    }
    
}
