.pagination {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px
}

.hide {
  display: none;
}

.pagination_section_1,
.pagination_section_2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination button:disabled {
  cursor: not-allowed;
}

.pagination input {
  width: 150px;
  margin-inline-end: 10px;
  height: 40px;
  outline: none;
  padding-inline-start: 10px;
  border: none;
  border-radius: 10px;
  background: #46A0CD;
  color: #fff;

}
.pagination input::placeholder {
  color: #fff;
  opacity: .4;
}

.pagination select {
  padding-inline-start: 10px;
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 10px;
  background: #46A0CD;
  color: #fff;
}

.pagination button {
  background: #46A0CD;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}


/* ====================== */
/* 
.container {
  display: inline-block;
  position: relative;
}
.index {
  cursor: pointer;
  display: inline;
  margin-right: 29.5px;
  padding: 5px;
  user-select: none;
  -moz-user-select: none;
}
.index:last-child {
  margin: 0;
}
svg {
  left: -13px;
  position: absolute;
  top: -11px;
  transition: transform 500ms;
  width: 46px;
}
path {
  fill:none;
  stroke:#2FB468;
  stroke-dasharray: 150 150;
  stroke-width:15;
}
.container.open:not(.flip) path {
  animation: OpenRight 500ms;
}
.container.open.flip path {
  animation: OpenLeft 500ms;
}
.container.i1 svg {
  transform: translateX(0);
}
.container.i2 svg {
  transform: translateX(50px);
}
.container.i3 svg {
  transform: translateX(102px);
}
.container.i4 svg {
  transform: translateX(154px);
}
.container.i5 svg {
  transform: translateX(206px);
}
@keyframes OpenRight {
  25% { stroke-dasharray: 100 150; }
  60% { stroke-dasharray: 100 150; }
  100% { stroke-dasharray: 150 150; }
}
@keyframes OpenLeft {
  25% { stroke-dashoffset: -50px; }
  60% { stroke-dashoffset: -50px; }
  100% { stroke-dashoffset: 0; }
} */

.CustomPagination{
  margin-top: 20px;
  display: flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  flex-wrap: wrap;
}
.CustomPagination button{
  background-color: #46A0CD;
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  margin: 5px;
}
 